import { navigate, graphql, StaticQuery } from 'gatsby';
import React from 'react';

import phpScriptsUrl from '../../phpScriptsUrl';
import { Form, FormError, FormHeader, FormItems, FormSubmit } from '../components/form';
import Input from '../components/input';
import Layout from '../components/layout';
import { Content, ContentDesc, Footer, FooterLinkBack, Header, Page } from '../components/page';
import Seo from '../components/seo';
import SvgArrowLeft from '../svg/arrowLeft';
import datoCmsGrahpQl from '../utils/dato-cms-graphql';
import getString from '../utils/get-string';

class ForgotenPassword extends React.Component {
  state = {
    form: {},
    errorMessages: [],
    userExists: undefined,
    submitProcessing: false,
  }

  handleChange = event => {
    const name = event.target.name
    const value =
      event.target.type !== 'checkbox' || event.target.type !== 'radio'
        ? event.target.value
        : event.target.checked

    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [name]: value,
      },
    }))
  }

  submitHandler = event => {
    this.setState({ submitProcessing: true })
    this.checkUserExists()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidMount() {
    this._isMounted = true
  }

  checkUserExists = () => {
    const query = `expert(filter: {email: {eq: "${this.state.form.email}"}}) {
      id
    }`

    return new Promise(resolve => datoCmsGrahpQl(query, resolve)).then(
      ({ expert }) => {
        if (this._isMounted)
          this.setState({
            userExists: expert ? true : false,
            submitProcessing: false,
            errorMessages: expert
              ? []
              : ['User with this email does not exist'],
          })
        if (this._isMounted && expert) {
          this.sendEmailToUser({
            ...this.state.form,
            id: expert.id,
            baseUrl: `${this.props.location.protocol}//${
              this.props.location.host
            }`,
            resetUrl: '/reset-password',
          })
          navigate(`/forgotten-password-affirmation`)
        }
      }
    )
  }

  formEncode(obj) {
    var str = []
    for (var p in obj)
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
    return str.join('&')
  }

  sendEmailToUser = data => {
    window.fetch(`${phpScriptsUrl}forgotten-password.php`, {
      method: 'POST',
      mode: 'no-cors',
      headers: { 'Content-type': 'application/x-www-form-urlencoded' },
      body: this.formEncode(data),
    })
  }

  render() {
    //console.log(this.props.location.search)

    return (
      <Layout>
        <Seo
          title={getString(this.props.strings, "11220911")}
          description={getString(this.props.strings, "11252261")}
        />

        <Page type="short">
          <Form onSubmit={this.submitHandler}>
            <Header closeUrl="/lessons">{getString(this.props.strings, "11220916")}</Header>
            <Content>
              <FormHeader>
                <ContentDesc>{getString(this.props.strings, "11220916")}</ContentDesc>
                <h1>{getString(this.props.strings, "11220922")}</h1>
                <p>
                  {getString(this.props.strings, "11252261")}
                </p>
              </FormHeader>

              <FormItems>
                <dt>
                  <label htmlFor="email">
                    {getString(this.props.strings, "11252604")}
                  </label>
                </dt>
                <dd>
                  <Input
                    size="lg"
                    id="email"
                    type="email"
                    name="email"
                    required={true}
                    onChange={this.handleChange}
                  />
                </dd>
              </FormItems>

              {this.state.errorMessages.length > 0 &&
                this.state.errorMessages.map((i, k) => (
                  <FormError key={k}>{i}</FormError>
                ))}
            </Content>
            <Footer>
              <FormSubmit disabled={this.state.submitProcessing}>
                {getString(this.props.strings, "11252813")}
              </FormSubmit>
              <FooterLinkBack to="/login">
                <SvgArrowLeft />
                {getString(this.props.strings, "11220979")}
              </FooterLinkBack>
            </Footer>
          </Form>
        </Page>
      </Layout>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        strings: allDatoCmsLanguageString(filter: {originalId: {in: ["11220911", "11252261", "11220916", "11220922", "11252604", "11252813", "11220979"]}}) {
          edges {
            node {
              string
              original
              originalId
            }
          }
        }
      }
    `}
    render={data => <ForgotenPassword {...data} {...props} />}
  />
)
